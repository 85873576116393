.landing-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .lineGirl-container {
    max-height: inherit;
    img {
      height: 100vh;
    }
  }

  .details-container {
    height: stretch;
    display: flex;
    padding: 5rem 0px 0px 7rem;
    flex-direction: column;
    align-items: flex-start;
    header {
      display: flex;
      align-items: center;
      .menuItem {
        margin-right: 2rem;
        color: #2a2929;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 30px;
        cursor: pointer;
        &.selected {
          color: #171616;
          font-weight: 600;
          border-bottom: 1px solid #171616;
        }
        &:hover {
          color: #222121;
          border-bottom: 1px solid #22212147;
        }
      }
    }
    .personalDetails-container {
      max-width: 618px;
      margin-top: 8rem;
      h1 {
        color: #171616;
      }
      .job {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.25px;
        color: #171616;
      }
      .description {
        margin-top: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #2a2929;
      }
    }
    .socials-container {
      margin-top: 100px;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: auto;
        height: 28px;
        margin-right: 30px;
      }
    }
  }
}
